import {useTranslation} from 'hooks/translations/useCustomTranslation';
import {serverSideTranslations} from 'next-i18next/serverSideTranslations';
import {useRouter} from 'next/router';
import {InferGetServerSidePropsType} from 'next/types';
import {useState} from 'react';

import {DefaultPagePropsProvider, TDefaultProps} from 'utils/dataFetching/DefaultPagePropsProvider';
import getDefaultPageProps from 'utils/dataFetching/getDefaultPageProps';
import {translationFiles} from 'utils/translations/constants';
import {redirectToLocalePath} from 'utils/translations/redirectLocale';
import {setCurrentLocale} from 'utils/translations/setCurrentLocale';

import {Box} from 'components/basic-components';
import LoginForm from 'components/form-components/LoginForm';
import PasswordForgotForm from 'components/form-components/PasswordForgotForm';
import ContentWrapper from 'components/layout-components/ContentWrapper/ContentWrapper';
import Layout from 'components/layout-components/Layout/Layout';
import LoginModalContent from 'components/section-components/login/LoginModal/LoginModalContent';

export async function getServerSideProps(ctx) {
  const {locale, locales} = ctx;
  const redirect = redirectToLocalePath({ctx, page_locale, path_mapping});
  if (redirect) return redirect;
  setCurrentLocale(locale);

  try {
    return {
      props: {
        defaultProps: await getDefaultPageProps({locale, locales, seoPath: 'anmeldung'}),

        ...(await serverSideTranslations(locale, translationFiles)),
      }, // will be passed to the page component as props
    };
  } catch (err) {
    return {
      notFound: true,
      props: {
        defaultProps: await getDefaultPageProps({locale, locales}),
      },
    };
  }
}
// will be able to inferServerSide PRops with next 12.3.2
// https://github.com/vercel/next.js/pull/40635
const DynamicPage: InferGetServerSidePropsType<any> = ({
  defaultProps,
}: {
  defaultProps: TDefaultProps;
}) => {
  // const  {isLoading, error, data: groups = {} } =  useGetApiV1GetReservationGroups(reservationId);

  const {t} = useTranslation('loginModal');
  const router = useRouter();

  const [isForgotPasswordClicked, setIsForgotPasswordClicked] = useState(false);
  const [isForgotPasswordEmailSuccess, setIsForgotPasswordEmailSuccess] = useState(false);

  const handleForgotPasswordClick = () => {
    setIsForgotPasswordClicked(true);
  };

  const handleBackToLoginClick = () => {
    setIsForgotPasswordClicked(false);
  };

  const handleSuccessPWEmail = () => {
    if (isForgotPasswordClicked) {
      setIsForgotPasswordEmailSuccess(true);
    }
  };

  const handleSuccess = () => {
    if (router?.query?.callbackUrl) {
      router.push(`${router?.query?.callbackUrl}`);
    } else {
      router.push('/mein-starcar/');
    }
  };

  return (
    <DefaultPagePropsProvider defaultProps={defaultProps}>
      <Layout
        translatedSlugs={[
          {slug: 'anmeldung', locale: 'de'},
          {slug: 'login', locale: 'en'},
        ]}
      >
        <div style={{zIndex: 1}}>
          {/* <Head> */}
          {/* {metaTags} */}
          {/* <title>{t('Anmeldung')} </title> */}
          {/* </Head> */}

          <ContentWrapper>
            <Box
              sx={{
                position: 'relative',
                margin: '50px 3%',
                outline: '1px solid black',
                borderRadius: '4px',
              }}
            >
              <LoginModalContent
                title={
                  isForgotPasswordClicked && isForgotPasswordEmailSuccess
                    ? t('confirmChange')
                    : isForgotPasswordClicked
                    ? t('resetPassword')
                    : t('login')
                }
                hideRegisterText={isForgotPasswordClicked}
              >
                {isForgotPasswordClicked ? (
                  <PasswordForgotForm
                    onGoToLoginClick={handleBackToLoginClick}
                    handleSuccessPWEmail={handleSuccessPWEmail}
                  />
                ) : (
                  <LoginForm
                    onLoginSuccess={() => handleSuccess}
                    onForgotPasswordClick={handleForgotPasswordClick}
                  />
                )}
              </LoginModalContent>
            </Box>
          </ContentWrapper>
        </div>
      </Layout>
    </DefaultPagePropsProvider>
  );
};

export default DynamicPage;

export const path_mapping = {de: 'anmeldung', en: 'login'};
export const page_locale = 'de';
